<template>
	<div v-show="show" style="background-color: rgba(0,0,0,0.3);width: 100vw;height:100vh;z-index: 2002;position: fixed;top: 0;left: 0;">
		<el-dialog
		  title="查看座次"
		  :visible.sync="show"
		  width="85%">
		  <div style="width: 100%;height: 80vh;overflow-y:auto;padding: 20px;font-size: 16px;">
			  <div class="left_info" style="width: 100%;overflow: scroll;padding: 0 20px;">
			  	<div :style="{width:elewidth+'px',display:'flex',alignItems:'center',justifyContent:'center'}">
			  		<div class="zxt">
			  			<div class="zxt_item" v-for="(item,index) in zhuxtailist" style="width: max-content;"
			  				@click="clickname(item)">
			  				<span :class="item.name=='不可坐'?'desb':''"
			  					style="height:50px; font-size: 12px;color: #666;border: 1px solid #666; padding: 2px 5px;border-radius: 5px;display: flex;flex-direction: column;align-items: center;">
			  					<span class="overhide">{{item.name}}</span>
			  					<span>{{item.xu}}</span>
			  				</span>
			  			</div>
			  		</div>
			  	</div>
			  
			  	<div class="zxt_im" :style="{width:elewidth+'px'}">主席台</div>
			  
			  	<div class="zuowei" style="margin-top: 40px;width: 100%;">
			  		<div class="zuowei_pai" v-for="(item,index) in gzxlist">
			  			<!-- <div style="width: 40px;">
			  				{{index+1}}排
			  			</div> -->
			  			<div>
			  				<div id="innercontent"
			  					style="display: flex;align-items: center;justify-content: center;flex: 1;width: max-content;">
			  					<div class="zuowei_lie" v-for="(item2,index2) in item" @click="clickname(item2)">
			  						<span :class="item2&&item2.name=='不可坐'?'desb':''"
			  							style="height:50px; font-size: 12px;color: #666;border: 1px solid #666; padding: 2px 5px;border-radius: 5px;display: flex;flex-direction: column;align-items: center;">
			  							<span class="overhide">{{item2?item2.name:'无人'}}</span>
			  							<span>{{item2?item2.xu:''}}</span>
			  						</span>
			  						<!-- <span style="color: #ccc;font-size: 20rpx;">{{sunxu[index2]}}</span> -->
			  					</div>
			  				</div>
			  			</div>
			  
			  
			  		</div>
			  	</div>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="show = false">取 消</el-button>
		    <el-button type="primary" @click="show = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				show:false,
				id:'',
				tabsList: [],
				show: false,
				zhu: 0,
				pai: 0,
				lie: 0,
				sunxu: [],
				zxtsunxu: [],
				newxuhao: '',
				yxu: '',
				itemid: '',
				zhuxtailist: [], //主席台列表
				gzxlist: [], //观众席列表
				elewidth: '',
				detail: '',
				role: ''
				
			}
		},
		methods:{
			clickname(item) {
				console.log(item)
				// if(this.role!=1){
				// 	return false
				// }
				if (!item.name||item.name=='不可坐') {
					return false
				}
				this.$prompt('请输入新的序号', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^\d+$/,
					inputErrorMessage: '请输入数字'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/invest_research_wyroomzc/tzzc',
						params: {
							id:item.id,
							yxu:item.xu,
							xxu:value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.getlist()
					})
					
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});
				})
			},
			getlist(){
				this.$post({
					url: '/api/invest_research_wymeeting/details',
					params: {
						id: this.id,
					}
				}).then((res) => {
					this.detail = res.data
				})
				
				this.$post({
					url: '/api/invest_research_wyroomzc/hyzc',
					params: {
						wymeeting_id: this.id,
					}
				}).then((res) => {
					this.pai = res.data.room.pai
					this.zhu = res.data.room.zhu
					this.lie = res.data.room.lie
					this.zhuxtailist = []
				
					let jis = []
					let ous = []
					for (let i = 1; i <= this.lie; i++) {
						if (i % 2 == 0) {
							ous.push(i)
						} else {
							jis.push(i)
						}
					}
					jis.sort((a, b) => b - a)
					this.sunxu = jis.concat(ous)
				
				
					//排列主席台
					let zhulist = []
					res.data.zuoci.zhu.forEach((item) => {
						let obj = {
							switch: item.switch //1可坐人，2不可坐人
						}
						zhulist.push(obj)
					})
					for (let i = 0; i < this.zhu; i++) {
						this.zhuxtailist.push(res.data.list[i])
					}
					let zxtjs = []
					let zxtos = []
					this.zhuxtailist.forEach((item, index) => {
						if (item.xu % 2 == 0) {
							zxtos.push(item)
						} else {
							zxtjs.push(item)
						}
					})
					zxtjs.reverse()
					this.zhuxtailist = zxtjs.concat(zxtos)
					for (let i = 0; i < this.zhu; i++) {
						if (zhulist[i].switch == 2) {
							this.zhuxtailist[i] = {
								xu: this.zhuxtailist[i].xu,
								name: '不可坐'
							}
						}
					}
				
				
				
				
				
				
				
				
					//排列观众席
					let gzlist = []
					res.data.zuoci.pai.forEach((item) => {
						let gzl = []
						item.lie.forEach((item2, index2) => {
							let gzobj = {
								switch: item2.switch //1可坐人，2不可坐人
							}
							gzl.push(gzobj)
						})
						gzlist.push(gzl)
				
					})
					let gzuserlist = []
					for (let i = 0; i < res.data.list.length; i++) {
						if (i >= this.zhu) {
							gzuserlist.push(res.data.list[i])
						}
					}
				
					this.gzxlist = []
					gzlist.forEach((item, index) => {
						let innergzxlist = []
						item.forEach((item2, index2) => {
							innergzxlist.push(gzuserlist[index * this.lie + index2])
						})
						this.gzxlist.push(innergzxlist)
					})
				
					let newuserlist = []
					this.gzxlist.forEach((item, index) => {
						let gzxjs = []
						let gzxos = []
						let newinlist = []
						item.forEach((item2, index2) => {
							if ((index2 + 1) % 2 == 0) {
								gzxos.push(item2)
							} else {
								gzxjs.push(item2)
							}
						})
						gzxjs.reverse()
						newinlist = gzxjs.concat(gzxos)
						newuserlist.push(newinlist)
					})
					this.gzxlist = newuserlist
					gzlist.forEach((item, index) => {
						let innergzxlist = []
						item.forEach((item2, index2) => {
							if (item2.switch == 2) {
								this.gzxlist[index][index2] = {
									xu: '',
									name: '不可坐'
								}
							}
						})
					})
					this.$nextTick(() => {
						const element = document.querySelector('#innercontent');
						const elementRect = element.getBoundingClientRect();
						this.elewidth = elementRect.width
				
					})
				})
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	.left_info {
		height:95%;
		overflow-y: auto;
		padding: 15px;
		margin-top: 25px;
		box-sizing: border-box;
	}
	
	.zxt {
		display: flex;
		align-items: center;
		justify-content: center;
		width: fit-content;
		padding-left: 40px;
	}
	
	.zuowei_pai {
		display: flex;
		margin-bottom: 20px;
		width: 100%;
		align-items: center;
	}
	
	.zuowei_lie {
		padding: 5px 10px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
	}
	
	.zuowei_lie:nth-last-child(1) {
		margin-right: 0;
	}
	
	.zxt_im {
		background-color: rgb(180, 180, 180);
		width: 80%;
		padding: 3px 0;
		text-align: center;
		font-size: 16px;
	}
	
	.zxt_item {
		padding: 5px 10px;
		margin-right: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		cursor: pointer;
	}
	
	.zxt_item:nth-last-child(1) {
		margin-right: 0;
	}
	
	.desb {
		background-color: rgb(180, 180, 180);
	}
	
	.desb {
		background-color: rgb(180, 180, 180);
	}
	
	.redbox {
		background-color: rgb(232, 45, 49);
		color: #fff !important;
	}
	
	.overhide {
		width: 50px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}
</style>